import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialModule } from './material.module';

// import { SwiperModule } from 'ngx-swiper-wrapper';
// import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { HeaderBarComponent } from './header-bar/header-bar.component';
import { HomeComponent } from './home/home.component';
import { ResortListComponent } from './resort-list/resort-list.component';
import { ResortDetailsComponent } from './resort-details/resort-details.component';
import { ModalGalleryComponent } from './resort-details/modal-gallery/modal-gallery.component';
import { ModalFilterComponent } from './resort-list/modal-filter/modal-filter.component';

import { MatCarouselModule } from '@ngmodule/material-carousel';

// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto'
// };

@NgModule({
  declarations: [
    AppComponent,
    HeaderBarComponent,
    HomeComponent,
    ResortListComponent,
    ResortDetailsComponent,
    ModalGalleryComponent,
    ModalFilterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HttpClientModule,
    // SwiperModule,
    MatCarouselModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: HomeComponent }
    ]),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAEtQ8L4pvasFX6AyRbNrnk5YT39YRmYjA'
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),
    NgbModule // <---
  ],
  providers: [
    MaterialModule,
    GoogleMapsAPIWrapper
  ],
  entryComponents: [
    ModalGalleryComponent,
    ModalFilterComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
