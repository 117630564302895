import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResortListComponent } from './resort-list/resort-list.component';
import { ResortDetailsComponent } from './resort-details/resort-details.component';
import { ModalGalleryComponent } from './resort-details/modal-gallery/modal-gallery.component';

const routes: Routes = [
  { path: 'list', component: ResortListComponent },
  { path: 'listing', component: ResortDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
