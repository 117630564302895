import { Injectable } from '@angular/core';

import { HttpClient, HttpClientModule, HttpHeaders, HttpParameterCodec, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResortsService {

  searchResult: SearchMap = {
    'searchable_states':[],
    'searchable_cities':[],
    'searchable_names':[],
  };

  searchStrings = [];
  searchMap = {};
  popularDestinations: any = [];

  public affiliateLink = 'http://www.anrdoezrs.net/links/9175705/type/dlg/';
  // public tripLink = 'https://www.trip.com/hotels/los-angeles-hotel-detail-733384';
  public tripLink = 'https://www.trip.com/hotels/';
  public tripDetails = '?&ci=2019-10-14&co=2019-10-16';

  constructor(private httpClient: HttpClient) { }

  public getSearchStrings() {
    if (this.searchStrings && this.searchStrings.length > 0) {
    } else {
      this.getSearchItems().subscribe((data)=>{
        if (data) {

          this.searchResult = data;
          this.popularDestinations = data['popular_locations'];
          // this.searchResult.searchable_states = data.searchable_states;
          var list;
          var i;

          if (this.searchResult.searchable_states) {
            this.fillSearchStrings(data.searchable_states);
          }

          if (data.searchable_cities) {
            this.fillSearchStrings(data.searchable_cities);          
          }

          if (data.searchable_names) {
            this.fillSearchStrings(data.searchable_names);
          }
        }
      });
    }
    return this.searchStrings;
  }

  public getSearchMap() {
    return this.searchMap;
  }

  public getPopularDestinations() {
    return this.popularDestinations;
  }

  public getAffiliateLink() {
    return this.affiliateLink;
  }

  public getTripLink() {
    return this.tripLink;
  }

  private fillSearchStrings(list) {
    if (list && list.length > 0) {
      var item;
      for (var i = 0; i < list.length; i++) {
        item = list[i];
        if (item.search_str && item.artifact_type) {
          this.searchStrings.push(item.search_str);
          this.searchMap[item.search_str] = {
            'type': item.artifact_type
          }
          if (item.country) {
            this.searchMap[item.search_str].country = item.country;
          }
          if (item.state_code) {
            this.searchMap[item.search_str].state_code = item.state_code;
          }
          if (item.city) {
            this.searchMap[item.search_str].city = item.city;
          }
          if (item.name) {
            this.searchMap[item.search_str].name = item.name;
          }
          // this.searchMap.push({'search_str' : list[i].search_str, 'artifact_type':list[i].artifact_type});
        }
      }
    }
  }

  getSearchItems(): Observable<SearchMap> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const options = {
      headers
    };

    var path = `https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/homepage`;

    return this.httpClient.get<SearchMap>(path, options);
  }
}

interface SearchMap {
  searchable_states: any;
  searchable_cities: any;
  searchable_names: any;
}