import { Injectable } from '@angular/core';

import { DatePipe } from '@angular/common'

import { distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  checkIn: Date;
  checkOut: Date;
  city: string;
  country: string;
  state_code: string
  name: string;

  guests: number;
  children: number;

  place: string;
  placeType: string;

  guestOptions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  datePipe = new DatePipe('en-US'); 

  constructor() { }

  // initiateForm(form, searchStrings, searchMap) {
  //   if (form) {
  //     if (this.place) {
  //       form.get("placeName").setValue(this.place);
  //     }
  
  //     if (this.checkIn) {
  //       form.get("checkInDate").setValue(this.checkIn);
  //     }
  
  //     if (this.checkOut) {
  //       form.get("checkOutDate").setValue(this.checkOut);
  //     }
  
  //     if (this.guests) {
  //       form.get("guestCount").setValue(this.guests);
  //     }
  
  //     if (this.children) {
  //       form.get("childrenCount").setValue(this.children);
  //     }

  //     form.get('placeName').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
  //       var region;
  
  //       for (var i in searchStrings) {
  //         region = searchStrings[i];
  //         if (form.get('placeName').value && form.get('placeName').value == region) {
  //           this.setStateCode(null);
  //           this.setCity(null);
  //           this.setName(null);
  //           this.setPlace(region);
  
  //           if (searchMap[region].type == 'STATE') {
  //             this.setStateCode(region);
  //           } else if (searchMap[region].type == 'CITY') {
  //             this.setCity(region);
  //           } else if (searchMap[region].type == 'NAME') {
  //             this.setName(region);
  //           }
  
  //           this.setCountry(searchMap[region].country);
  //           this.setStateCode(searchMap[region].state_code);
  
  //           if (searchMap[region].city) {
  //             this.setCity(searchMap[region].city);
  //           }
  
  //           if (searchMap[region].name) {
  //             this.setName(searchMap[region].name);
  //           }
  
  //           break;
  //         }
  //       }
  //     })
      
  //     form.get('checkInDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
  //       if (form.get('checkInDate').value) {
  //         this.setCheckIn(this.datePipe.transform(form.get('checkInDate').value, 'yyyy-MM-dd'))
  //       }
  //     })
  
  //     form.get('checkOutDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
  //       if (form.get('checkOutDate').value) {
  //         this.setCheckOut(this.datePipe.transform(form.get('checkOutDate').value, 'yyyy-MM-dd'))
  //       }
  //     })

  //     form.get('guestCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
  //       if (form.get('guestCount').value) {
  //         form.setGuests(form.get('guestCount').value);
  //       }
  //     })
  
  //     form.get('childrenCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
  //       if (form.get('childrenCount').value) {
  //         form.setChildren(form.get('childrenCount').value);
  //       }
  //     })
  //   }
  // }

  setGuests(guests) {
  	this.guests = guests;
  }

  setChildren(children) {
    this.children = children;
  }

  setCheckIn(date) {
  	this.checkIn = date;
  }

  setCheckOut(date) {
  	this.checkOut = date;
  }

  setCity(city) {
  	this.city = city;
  }

  setCountry(country) {
  	this.country = country;
  }

  setStateCode(state_code) {
  	this.state_code = state_code;
  }

  setName(name) {
    this.name = name;
  }

  setPlace(place) {
    this.place = place;
  }

  setPlaceType(type) {
    this.placeType = type;
  }

  getGuestOptions() {
    return this.guestOptions;
  }

  getGuests() {
  	return this.guests;
  }

  getChildren() {
    return this.children;
  }

  getCheckIn() {
  	return this.checkIn;
  }

  getCheckOut() {
  	return this.checkOut;
  }

  getCity() {
  	return this.city;
  }

  getCountry() {
  	return this.country;
  }

  getStateCode() {
  	return this.state_code;
  }

  getName() {
    return this.name;
  }

  getPlace() {
    return this.place;
  }

  getPlaceType() {
    return this.placeType;
  }
}
