import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common'

import { HttpClient, HttpClientModule, HttpHeaders, HttpParameterCodec } from '@angular/common/http';

import { Observable, throwError} from 'rxjs';
import { catchError, map, startWith, distinctUntilChanged} from 'rxjs/operators';

import { SearchService } from '../search.service';
import { ResortsService } from '../resorts.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public homeSearchForm: FormGroup;

  searchStrings:any = [];
  searchMap:any = {};

  searchPlaces: Observable<string[]>;

  minCheckInDate = new Date();
  minCheckOutDate = new Date();
  maxCheckInDate: any;
  guestOptions: any;

  popularLocations: any;
  LAResorts: any;
  skiResorts: any;

  datePipe = new DatePipe('en-US'); 

  constructor(private router:Router, private httpClient: HttpClient, private searchService: SearchService, private resortsService: ResortsService) {}

  ngOnInit() {
    let header = document.getElementById('header');
    header.classList.remove('header-sticky');
    // this.popularLocations = this.resortsService.getPopularDestinations();
    // this.searchStrings = this.resortsService.getSearchStrings();

    this.guestOptions = this.searchService.getGuestOptions();

    this.homeSearchForm = new FormGroup({
      placeName: new FormControl(),
      checkInDate: new FormControl(),
      checkOutDate: new FormControl(),
      guestCount: new FormControl(),
      childrenCount: new FormControl(),
    });

    var today = new Date();

    this.getLAListings().subscribe((data)=>{
      if (data && data[0]) {
        this.LAResorts = data;

        var resort;
        for (var i in this.LAResorts) {
          resort = this.LAResorts[i];

          resort.country = 'USA',
          resort.state_code = 'CA',
          resort.city = 'Los Angeles'
        }
      }
    });

    this.getHomePageListings().subscribe((data)=>{
      if (data && data['popular_locations']) {
        this.popularLocations = data['popular_locations'];
      }
    });

    if (this.searchService.getName()) {
      this.searchService.setName('');
    }

    if (this.searchService.getPlace()) {
      this.homeSearchForm.get("placeName").setValue(this.searchService.getPlace());
    }

    if (this.searchService.getCheckIn()) {
      this.homeSearchForm.get("checkInDate").setValue(this.searchService.getCheckIn());
    }

    if (this.searchService.getCheckOut()) {
      this.homeSearchForm.get("checkOutDate").setValue(this.searchService.getCheckOut());
    }

    if (this.searchService.getGuests()) {
      this.homeSearchForm.get("guestCount").setValue(this.searchService.getGuests());
    }

    if (this.searchService.getChildren()) {
      this.homeSearchForm.get("childrenCount").setValue(this.searchService.getChildren());
    }

    this.homeSearchForm.get('placeName').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      var region;

      for (var i in this.searchStrings) {
        region = this.searchStrings[i];
        if (this.homeSearchForm.get('placeName').value && this.homeSearchForm.get('placeName').value == region) {
          this.searchService.setStateCode(null);
          this.searchService.setCity(null);
          this.searchService.setName(null);
          this.searchService.setPlace(region);

          if (this.searchMap[region].type == 'STATE') {
            this.searchService.setStateCode(region);
          } else if (this.searchMap[region].type == 'CITY') {
            this.searchService.setCity(region);
          } else if (this.searchMap[region].type == 'NAME') {
            this.searchService.setName(region);
          }

          this.searchService.setCountry(this.searchMap[region].country);
          this.searchService.setStateCode(this.searchMap[region].state_code);

          if (this.searchMap[region].city) {
            this.searchService.setCity(this.searchMap[region].city);
          }

          if (this.searchMap[region].name) {
            this.searchService.setName(this.searchMap[region].name);
          }

          break;
        }
      }
    })

    this.homeSearchForm.get('checkInDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.homeSearchForm.get('checkInDate').value) {
        // this.searchService.setCheckIn(this.homeSearchForm.get('checkInDate').value);
        this.searchService.setCheckIn(this.datePipe.transform(this.homeSearchForm.get('checkInDate').value, 'yyyy-MM-dd'))
      
      }
      if (this.homeSearchForm.get('checkInDate').value) {
        this.minCheckOutDate.setDate(this.homeSearchForm.get('checkInDate').value.getDate() + 1);
      }
    })

    this.homeSearchForm.get('checkOutDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.homeSearchForm.get('checkOutDate').value) {
        // this.searchService.setCheckOut(this.homeSearchForm.get('checkOutDate').value);

        this.searchService.setCheckOut(this.datePipe.transform(this.homeSearchForm.get('checkOutDate').value, 'yyyy-MM-dd'))
        if (!this.homeSearchForm.get('checkInDate').value) {
          this.maxCheckInDate = new Date();
          this.maxCheckInDate.setDate(this.homeSearchForm.get('checkOutDate').value.getDate() - 1);
        }
      }
    })

    this.homeSearchForm.get('guestCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.homeSearchForm.get('guestCount').value) {
        this.searchService.setGuests(this.homeSearchForm.get('guestCount').value);
      }
    })

    this.homeSearchForm.get('childrenCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.homeSearchForm.get('childrenCount').value) {
        this.searchService.setChildren(this.homeSearchForm.get('childrenCount').value);
      }
    })

    this.minCheckOutDate.setDate(this.minCheckInDate.getDate() + 1);

    this.searchPlaces = this.homeSearchForm.get('placeName').valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value))
    );
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();

    if (this.searchStrings && this.searchStrings.length > 0) {

    } else {
      this.searchStrings = this.resortsService.getSearchStrings();
      this.searchMap = this.resortsService.getSearchMap();
    }
    var results = [];
    if (this.searchStrings) {
      results = this.searchStrings.filter(option => option.toLowerCase().includes(filterValue)); 
    }
    return results;
  }

  getLAListings() {
    var path = 'https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/resort?country=USA&state_code=CA&city=Los%20Angeles&select=name,photos&limit=5';
    return this.httpClient.get(path);
  }

  getHomePageListings() {
    var path = 'https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/homepage';
    return this.httpClient.get(path);
  }

  search() {
    this.openList();
    // this.openList(
    //   this.searchService.country, 
    //   this.searchService.state_code, 
    //   this.searchService.getCity(), 
    //   this.datePipe.transform(this.searchService.checkIn, 'yyyy-MM-dd'),
    //   this.datePipe.transform(this.searchService.checkOut, 'yyyy-MM-dd'), 
    //   this.searchService.guests);
  }

  selectListing(country, state_code, city, name) {
    if (country) {
      this.searchService.setCountry(country);
    }

    if (state_code) {
      this.searchService.setStateCode(state_code);
    }

    if (city) {
      this.searchService.setCity(city);
      this.searchService.setPlace(city);
    }

    if (name) {
      this.searchService.setName(name);
      this.searchService.setPlace(name);
    }

    this.openList();
  }

  openList() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "country": this.searchService.getCountry(),
        "state_code": this.searchService.getStateCode()
      }
    };

    if (this.searchService.getPlace()) {
      navigationExtras.queryParams.place = this.searchService.getPlace();
    }

    if (this.searchService.getCity()) {
      navigationExtras.queryParams.city = this.searchService.getCity();
    }

    if (this.searchService.getGuests()) {
      navigationExtras.queryParams.guests = this.searchService.getGuests();
    }

    if (this.searchService.getChildren()) {
      navigationExtras.queryParams.children = this.searchService.getChildren();
    }

    if (this.searchService.getCheckIn()) {
      // navigationExtras.queryParams.checkIn = this.datePipe.transform(this.searchService.getCheckIn(), 'yyyy-MM-dd');
      navigationExtras.queryParams.checkIn = this.searchService.getCheckIn();
    }

    if (this.searchService.getCheckOut()) {
      // navigationExtras.queryParams.checkOut = this.datePipe.transform(this.searchService.getCheckOut(), 'yyyy-MM-dd');
      navigationExtras.queryParams.checkOut = this.searchService.getCheckOut();
    }

    if (this.searchService.getName()) {
      navigationExtras.queryParams.name = this.searchService.getName();
      this.router.navigate(['listing'], navigationExtras);
    } else {
      this.router.navigate(['list'], navigationExtras);
    }
  }

}
