import { Component, OnInit, HostListener, NgZone, ViewChild, Injectable} from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { HttpClient, HttpClientModule, HttpParameterCodec } from '@angular/common/http';

import { Observable} from 'rxjs';
import { map, startWith, distinctUntilChanged} from 'rxjs/operators';


import { MouseEvent, MapsAPILoader, AgmMap, AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';

import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';

import { SearchService } from '../search.service';
import { ResortsService } from '../resorts.service';

import { ModalFilterComponent } from './modal-filter/modal-filter.component';

@Component({
  selector: 'app-resort-list',
  templateUrl: './resort-list.component.html',
  styleUrls: ['./resort-list.component.css']
})

export class ResortListComponent implements OnInit {

  public listSearchForm: FormGroup;

  searchStrings:any = [];
  searchMap:any = {};

  searchPlaces: Observable<string[]>;
  minCheckInDate = new Date();
  minCheckOutDate = new Date();
  maxCheckInDate: any;
  guestOptions: any;

  showSpinner: boolean = false;
  emptyList: boolean = false;

  fixedMap = false;
  zoom = 11;
  markers = [];
  resortCount = -1;

  resorts: any;
  geocoder: any;

  country = '';
  state_code = '';
  city = '';
  name = '';

  checkInDate: any;
  checkOutDate: any;
  guestCount: any;

  datePipe = new DatePipe('en-US'); 

  public location:Location = {
    lat:0,
    lng:0,
    zoom:0
  };

  @ViewChild(AgmMap, {static : false}) map: AgmMap;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public mapsApiLoader: MapsAPILoader, private zone: NgZone, private wrapper: GoogleMapsAPIWrapper, private httpClient: HttpClient, private searchService: SearchService, private resortsService: ResortsService, public dialog: MatDialog) {
    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });

    if (this.searchService) {
    } else {
      
    }
  }

  ngOnInit() {
    let header = document.getElementById('header');
    header.classList.add('header-sticky');
    window.scrollTo(0, 0);

    this.guestOptions = this.searchService.getGuestOptions();

    this.listSearchForm = new FormGroup({
      placeName: new FormControl(),
      checkInDate: new FormControl(),
      checkOutDate: new FormControl(),
      guestCount: new FormControl(),
      childrenCount: new FormControl()
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.country = params["country"];
      this.state_code = params["state_code"];
      this.city = params["city"];

      if (!this.searchService.getCountry() && params["country"]) {
        this.searchService.setCountry(params["country"]);
      }

      if (!this.searchService.getStateCode() && params["state_code"]) {
        this.searchService.setStateCode(params["state_code"]);
      }

      if (!this.searchService.getCity() && params["city"]) {
        this.searchService.setCity(params["city"]);
      }
      
      if (!this.searchService.getCheckIn() && params["checkIn"]) {
        this.checkInDate = params["checkIn"];
        this.searchService.setCheckIn(this.checkInDate);
      }

      if (!this.searchService.getCheckOut() && params["checkOut"]) {
        this.checkOutDate = params["checkOut"];
        this.listSearchForm.get("checkOutDate").setValue(this.checkOutDate);
      }

      if (!this.searchService.getGuests() && params["guests"]) {
        this.guestCount = params["guests"];
        this.searchService.setGuests(params["guests"]);
      }

      if (!this.searchService.getChildren() && params["children"]) {
        this.searchService.setChildren(params["children"]);
      }

      if (!this.searchService.getPlace() && params["place"]) {
        this.searchService.setPlace(params["place"]);
      }
    });

    this.initializeList();

    this.listSearchForm.get('placeName').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      var region;

      for (var i in this.searchStrings) {
        region = this.searchStrings[i];
        if (this.listSearchForm.get('placeName').value && this.listSearchForm.get('placeName').value == region) {
          this.searchService.setStateCode(null);
          this.searchService.setCity(null);
          this.searchService.setName(null);
          this.searchService.setPlace(region);

          if (this.searchMap[region].type == 'STATE') {
            this.searchService.setStateCode(region);
          } else if (this.searchMap[region].type == 'CITY') {
            this.searchService.setCity(region);
          } else if (this.searchMap[region].type == 'NAME') {
            this.searchService.setName(region);
          }

          this.searchService.setCountry(this.searchMap[region].country);
          this.searchService.setStateCode(this.searchMap[region].state_code);

          if (this.searchMap[region].city) {
            this.searchService.setCity(this.searchMap[region].city);
          }

          if (this.searchMap[region].name) {
            this.searchService.setName(this.searchMap[region].name);
          }

          break;
        }
      }
    })

    this.listSearchForm.get('checkInDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.listSearchForm.get('checkInDate').value) {
        // this.searchService.setCheckIn(this.listSearchForm.get('checkInDate').value);
        this.searchService.setCheckIn(this.datePipe.transform(this.listSearchForm.get('checkInDate').value, 'yyyy-MM-dd'))
      }
      if (this.listSearchForm.get('checkInDate').value) {
        var date = new Date (this.listSearchForm.get('checkInDate').value);
        this.minCheckOutDate.setDate(date.getDate() + 1);
        // this.minCheckOutDate.setDate(this.listSearchForm.get('checkInDate').value.getDate() + 1);
      }
    })

    this.listSearchForm.get('checkOutDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.listSearchForm.get('checkOutDate').value) {
        // this.searchService.setCheckOut(this.listSearchForm.get('checkOutDate').value);
        this.searchService.setCheckOut(this.datePipe.transform(this.listSearchForm.get('checkOutDate').value, 'yyyy-MM-dd'))

        if (!this.listSearchForm.get('checkInDate').value) {
          this.maxCheckInDate = new Date();
          var date = new Date (this.listSearchForm.get('checkOutDate').value);
          this.maxCheckInDate.setDate(date.getDate() - 1);
          // this.maxCheckInDate.setDate(this.listSearchForm.get('checkOutDate').value.getDate() - 1);
        }
      }
    })

    this.listSearchForm.get('guestCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.listSearchForm.get('guestCount').value) {
        this.showSpinner = true;
        this.searchService.setGuests(this.listSearchForm.get('guestCount').value);
      }
    })

    this.minCheckOutDate.setDate(this.minCheckInDate.getDate() + 1);

    this.searchPlaces = this.listSearchForm.get('placeName').valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value))
    );
  }

  private initializeList() {
    if (this.searchService.getName()) {
      this.searchService.setName('');
    }

    this.listSearchForm.get("placeName").setValue(this.searchService.getPlace());

    if (this.searchService.getPlace()) {
      this.listSearchForm.get("placeName").setValue(this.searchService.getPlace());
    }

    if (this.searchService.getCheckIn()) {
      this.listSearchForm.get("checkInDate").setValue(this.searchService.getCheckIn());
    }

    if (this.searchService.getCheckOut()) {
      this.listSearchForm.get("checkOutDate").setValue(this.searchService.getCheckOut());
    }

    if (this.searchService.getGuests()) {
      this.listSearchForm.get("guestCount").setValue(this.searchService.getGuests());
    }

    if (this.searchService.getChildren()) {
      this.listSearchForm.get("childrenCount").setValue(this.searchService.getChildren());
    }
    this.search();
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();

    if (this.searchStrings && this.searchStrings.length > 0) {

    } else {
      this.searchStrings = this.resortsService.getSearchStrings();
      this.searchMap = this.resortsService.getSearchMap();
    }
    var results = [];
    if (this.searchStrings) {
      results = this.searchStrings.filter(option => option.toLowerCase().includes(filterValue)); 
    }
    return results;
  }

  public getList() {
    // var city = encodeURIComponent(this.city);
    // var country = encodeURIComponent(this.country);
    // var state_code = this.state_code;
    // var checkInDate = this.checkInDate;
    // var checkOutDate = this.checkOutDate;

    var country = encodeURIComponent(this.searchService.getCountry());
    var state_code = this.searchService.getStateCode();
    // var city = encodeURIComponent(this.searchService.getCity());
    // var name = encodeURIComponent(this.searchService.getName());
    // var guests = this.searchService.getGuests();
    // var checkInDate = this.searchService.getCheckIn();
    // var checkOutDate = this.searchService.getCheckOut();

    var path = 'https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/resort?country=' + country + '&state_code=' + state_code;

    var city, guests, children, checkInDate, checkOutDate;
    if (this.searchService.getCity()) {
      city = encodeURIComponent(this.searchService.getCity());
      path = path + '&city=' + city;
    }

    // if (this.searchService.getName()) {
    //   name = encodeURIComponent(this.searchService.getName());
    //   path = path + '&name=' + name;
    // }

    // if (city) {
      // path = path + '&city=' + city;
    // }

    // if (name) {
    //   path = path + '&name=' + name;
    // }

    if (this.searchService.getCheckIn()) {
      checkInDate = this.searchService.getCheckIn();
      path = path + '&ci=' + checkInDate;
    }

    if (this.searchService.getCheckOut()) {
      checkOutDate = this.searchService.getCheckOut();
      path = path + '&co=' + checkOutDate;
    }

    if (this.searchService.getGuests()) {
      guests = this.searchService.getGuests();
      path = path + '&guests=' + guests;
    }

    if (this.searchService.getChildren()) {
      children = this.searchService.getChildren();
      path = path + '&children=' + children;
    }
    // var path = `https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/resort?country=${ country }&state_code=${ state_code }&city=${ city }&ci=${ checkInDate }&co=${ checkOutDate }`;

    // var path = `https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/resort?country=${ country }&state_code=${ state_code }&city=${ city }&ci=${ checkInDate }&co=${ checkOutDate }`;
    // return this.httpClient.get(`https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/resort?country=${ country }&state_code=${ state_code }&city=${ city }`);
    return this.httpClient.get(path);

    // if (this.searchService.getApiUrl()) {
    //   var path = this.searchService.getApiUrl();
    //   return this.httpClient.get(path);
    // }
    // return;
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  mouseOver(i:number) {
    if (this.markers[i]) {
      this.location.lat = this.markers[i].lat;
      this.location.lng = this.markers[i].lng;
      this.markers[i].zIndex = 100;
      this.markers[i].iconUrl = "http://maps.google.com/mapfiles/ms/micons/blue-dot.png";
    }
  }

  mouseOut(i:number) {
    if (this.markers[i]) {
      this.markers[i].zIndex = 1;
      this.markers[i].iconUrl = "http://maps.google.com/mapfiles/ms/micons/red-dot.png";
    }
  }

  selectMarker(i) {
    if (this.markers[i]) {
      this.location.lat = this.markers[i].lat;
      this.location.lng = this.markers[i].lng;
      this.markers[i].iconUrl = 'http://maps.google.com/mapfiles/ms/micons/blue-dot.png';
    }
  }

  unselectMarker(i) {
    if (this.markers[i]) {
      this.markers[i].iconUrl = 'http://maps.google.com/mapfiles/ms/micons/yellow-dot.png';
    }
  }

  getMarkers(address) {
    this.geocoder.geocode({
      'address': address
    }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0].geometry.location) {
          var marker = {
            'lat':results[0].geometry.location.lat(), 
            'lng':results[0].geometry.location.lng(),
            'iconUrl':'http://maps.google.com/mapfiles/ms/micons/red-dot.png'
          };
          this.markers.push(marker);
        }
      } else {
        alert("Sorry, this search produced no results.");
      }
    })
  }

  findLocationByAddress(address) {
    if (!this.geocoder) 
    this.geocoder = new google.maps.Geocoder()
      this.geocoder.geocode({
        'address': address
      }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {

          for (var i = 0; i < results[0].address_components.length; i++) {
            let types = results[0].address_components[i].types
   
            if (types.indexOf('locality') != -1) {
              this.location.address_level_2 = results[0].address_components[i].long_name
            }
            if (types.indexOf('country') != -1) {
              this.location.address_country = results[0].address_components[i].long_name
            }
            if (types.indexOf('postal_code') != -1) {
              this.location.address_zip = results[0].address_components[i].long_name
            }
            if (types.indexOf('administrative_area_level_1') != -1) {
              this.location.address_state = results[0].address_components[i].long_name
            }
          }

          if (results[0].geometry.location) {
            if (!this.location.zoom) {
              this.location.zoom = 9;
            }

            this.location.lat = results[0].geometry.location.lat();
            this.location.lng = results[0].geometry.location.lng();

            // this.location.marker.lat = results[0].geometry.location.lat();
            // this.location.marker.lng = results[0].geometry.location.lng();
            // this.location.marker.draggable = true;
            this.location.viewport = results[0].geometry.viewport;
          }
          
          // this.location.zoom = this.location.zoom += 0.5;

          this.map.triggerResize();

      } else {
        alert("Sorry, this search produced no results.");
      }
    })
  }

  markerDragEnd(m: Marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  mapClicked() {}

  openListing(resort) {
    this.searchService.setName(resort.name);

    if (!this.searchService.getCountry()) {
      this.searchService.setCountry(resort.country);
    }

    if (!this.searchService.getStateCode()) {
      this.searchService.setStateCode(resort.state_code);
    }

    if (!this.searchService.getCity()) {
      this.searchService.setCity(resort.city);
    }

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "country": this.searchService.getCountry(),
        "state_code": this.searchService.getStateCode(),
        "city":this.searchService.getCity(),
        "name":this.searchService.getName()
      }
    };

    if (this.searchService.getGuests()) {
      navigationExtras.queryParams.guests = this.searchService.getGuests();
    }

    if (this.searchService.getChildren()) {
      navigationExtras.queryParams.children = this.searchService.getChildren();
    }

    if (this.searchService.getCheckIn()) {
      navigationExtras.queryParams.checkIn = this.searchService.getCheckIn();
    }

    if (this.searchService.getCheckOut()) {
      navigationExtras.queryParams.checkOut = this.searchService.getCheckOut();
    }
    
    this.router.navigate(['listing'], navigationExtras);
  }

  search() {
    this.resorts = [];
    this.markers = [];

    if (this.searchService.getName()) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "name": this.searchService.getName(),
          "country": this.searchService.getCountry(),
          "state_code": this.searchService.getStateCode()
        }
      };

      if (this.searchService.getPlace()) {
        navigationExtras.queryParams.place = this.searchService.getPlace();
      }

      if (this.searchService.getCity()) {
        navigationExtras.queryParams.city = this.searchService.getCity();
      }

      if (this.searchService.getGuests()) {
        navigationExtras.queryParams.guests = this.searchService.getGuests();
      }

      if (this.searchService.getChildren()) {
        navigationExtras.queryParams.children = this.searchService.getChildren();
      }

      if (this.searchService.getCheckIn()) {
        navigationExtras.queryParams.checkIn = this.searchService.getCheckIn();
      }

      if (this.searchService.getCheckOut()) {
        navigationExtras.queryParams.checkOut = this.searchService.getCheckOut();
      }

      this.router.navigate(['listing'], navigationExtras);
    } else {
      this.getList().subscribe((data)=>{
        if (data && data[0]) {
          this.resorts = data;
          this.resortCount = Object.keys(this.resorts).length;
          this.location.lat = this.resorts[0].coordinates.lat;
          this.location.lng = this.resorts[0].coordinates.lng;
          this.location.zoom = 12;

          if (this.map) {
            this.map.triggerResize();
          }

          var resort;
          for (var i = 0; i < this.resorts.length; i++) {
            resort = this.resorts[i];
            if (resort.photos && resort.photos.Uncategorized && resort.photos.Uncategorized.length > 0) {
              resort.image = resort.photos.Uncategorized[0];
            }

            this.markers.push(resort.coordinates);
            this.markers[i].iconUrl = 'http://maps.google.com/mapfiles/ms/micons/red-dot.png';
          }
        } else {
          this.resortCount = 0;
          this.emptyList = true;
        }
      });
    }
  }

  public openFilters() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { 
      "country": this.country,
      "state_code": this.state_code,
      "city":this.city,
      "checkInDate": this.checkInDate,
      "checkOutDate": this.checkOutDate,
      "guests":this.guestCount,
    };
    dialogConfig.height = '350px';
    dialogConfig.width = '300px';

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "country": this.country,
        "state_code": this.state_code,
        "city":this.city,
        "name":this.name
      }
    };

    this.dialog.open(ModalFilterComponent, dialogConfig).afterClosed().subscribe(value => {
      console.log(value);
      if (value && value.data) {
      console.log(value);
        this.initializeList();
      }
    });
  }
}

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?:string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}