import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common'

import { Observable} from 'rxjs';
import { map, startWith, distinctUntilChanged} from 'rxjs/operators';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { SearchService } from '../../search.service';
import { ResortsService } from '../../resorts.service';

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.css']
})
export class ModalFilterComponent implements OnInit {

  public modalSearchForm: FormGroup;

  searchStrings:any = [];
  searchMap:any = {};

  searchPlaces: Observable<string[]>;

  cityOptions: Observable<string[]>;
  minCheckInDate = new Date();
  minCheckOutDate = new Date();
  maxCheckInDate: any;
  guestOptions: any;

  datePipe = new DatePipe('en-US'); 

  constructor(public dialogRef: MatDialogRef<ModalFilterComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private searchService: SearchService, private resortsService: ResortsService) { }

  ngOnInit() {

    this.guestOptions = this.searchService.getGuestOptions();

    this.modalSearchForm = new FormGroup({
      placeName: new FormControl(),
      checkInDate: new FormControl(),
      checkOutDate: new FormControl(),
      guestCount: new FormControl(),
      childrenCount: new FormControl()
      // guestCount: new FormControl(this.data.guestCount)
    });

    //to be removed
    // this.modalSearchForm.get('placeName').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
    //   var region;
    //   // var usCities = this.resortsService.getUsCities();
    //   // for (var i in usCities) {
    //   //   region = usCities[i];
    //   //   if (this.modalSearchForm.get('placeName') && this.modalSearchForm.get('placeName') == region.city) {
          
    //   //     this.searchService.setCity(region.city);
    //   //     this.searchService.setCountry(region.country);
    //   //     this.searchService.setStateCode(region.state_code);
    //   //     break;
    //   //   }
    //   // }
    // })

    // this.modalSearchForm.get('checkInDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
    //   if (this.modalSearchForm.get('checkInDate').value) {
    //     this.searchService.setCheckIn(this.modalSearchForm.get('checkInDate').value);
    //   }
    //   if (this.modalSearchForm.get('checkInDate').value) {
    //     var date = new Date (this.modalSearchForm.get('checkInDate').value);
    //     this.minCheckOutDate.setDate(date.getDate() + 1);
    //     // this.minCheckOutDate.setDate(this.modalSearchForm.get('checkInDate').value.getDate() + 1);
    //   }
    // })

    // this.modalSearchForm.get('checkOutDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
    //   if (this.modalSearchForm.get('checkOutDate').value) {
    //     this.searchService.setCheckOut(this.modalSearchForm.get('checkOutDate').value);
    //     if (!this.modalSearchForm.get('checkInDate').value) {
    //       var date = new Date (this.modalSearchForm.get('checkOutDate').value);
    //       this.maxCheckInDate.setDate(date.getDate() - 1);
    //       // this.maxCheckInDate.setDate(this.modalSearchForm.get('checkOutDate').value.getDate() - 1);
    //     }
    //   }
    // })

    // this.modalSearchForm.get('guestCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
    //   if (this.modalSearchForm.get('guestCount').value) {
    //     this.searchService.setGuests(this.modalSearchForm.get('guestCount').value);
    //   }
    // })

    // this.minCheckOutDate.setDate(this.minCheckInDate.getDate() + 1);

    // // this.cityOptions = this.modalSearchForm.get('placeName').valueChanges.pipe(
    // //   startWith(''),
    // //   map(value => this.filterOptions(value))
    // // );



    this.modalSearchForm.get("placeName").setValue(this.searchService.getPlace());

    if (this.searchService.getPlace()) {
      this.modalSearchForm.get("placeName").setValue(this.searchService.getPlace());
    }

    if (this.searchService.getCheckIn()) {
      this.modalSearchForm.get("checkInDate").setValue(this.searchService.getCheckIn());
    }

    if (this.searchService.getCheckOut()) {
      this.modalSearchForm.get("checkOutDate").setValue(this.searchService.getCheckOut());
    }

    if (this.searchService.getGuests()) {
      this.modalSearchForm.get("guestCount").setValue(this.searchService.getGuests());
    }

    if (this.searchService.getChildren()) {
      this.modalSearchForm.get("childrenCount").setValue(this.searchService.getChildren());
    }


    this.modalSearchForm.get('placeName').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      var region;

      for (var i in this.searchStrings) {
        region = this.searchStrings[i];
        if (this.modalSearchForm.get('placeName').value && this.modalSearchForm.get('placeName').value == region) {
          this.searchService.setStateCode(null);
          this.searchService.setCity(null);
          this.searchService.setName(null);
          this.searchService.setPlace(region);

          if (this.searchMap[region].type == 'STATE') {
            this.searchService.setStateCode(region);
          } else if (this.searchMap[region].type == 'CITY') {
            this.searchService.setCity(region);
          } else if (this.searchMap[region].type == 'NAME') {
            this.searchService.setName(region);
          }

          this.searchService.setCountry(this.searchMap[region].country);
          this.searchService.setStateCode(this.searchMap[region].state_code);

          if (this.searchMap[region].city) {
            this.searchService.setCity(this.searchMap[region].city);
          }

          if (this.searchMap[region].name) {
            this.searchService.setName(this.searchMap[region].name);
          }

          break;
        }
      }
    })

    this.modalSearchForm.get('checkInDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.modalSearchForm.get('checkInDate').value) {
        // this.searchService.setCheckIn(this.modalSearchForm.get('checkInDate').value);
        this.searchService.setCheckIn(this.datePipe.transform(this.modalSearchForm.get('checkInDate').value, 'yyyy-MM-dd'))
      }
      if (this.modalSearchForm.get('checkInDate').value) {
        this.minCheckOutDate.setDate(this.modalSearchForm.get('checkInDate').value.getDate() + 1);
      }
    })

    this.modalSearchForm.get('checkOutDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.modalSearchForm.get('checkOutDate').value) {
        // this.searchService.setCheckOut(this.modalSearchForm.get('checkOutDate').value);
        this.searchService.setCheckOut(this.datePipe.transform(this.modalSearchForm.get('checkOutDate').value, 'yyyy-MM-dd'))

        if (!this.modalSearchForm.get('checkInDate').value) {
          this.maxCheckInDate = new Date();
          this.maxCheckInDate.setDate(this.modalSearchForm.get('checkOutDate').value.getDate() - 1);
        }
      }
    })

    this.modalSearchForm.get('guestCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.modalSearchForm.get('guestCount').value) {
        this.searchService.setGuests(this.modalSearchForm.get('guestCount').value);
      }
    })

    this.modalSearchForm.get('childrenCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.modalSearchForm.get('childrenCount').value) {
        this.searchService.setChildren(this.modalSearchForm.get('childrenCount').value);
      }
    })

    this.minCheckOutDate.setDate(this.minCheckInDate.getDate() + 1);

    this.searchPlaces = this.modalSearchForm.get('placeName').valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value))
    );
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();

    if (this.searchStrings && this.searchStrings.length > 0) {

    } else {
      this.searchStrings = this.resortsService.getSearchStrings();
      this.searchMap = this.resortsService.getSearchMap();
    }
    var results = [];
    if (this.searchStrings) {
      results = this.searchStrings.filter(option => option.toLowerCase().includes(filterValue)); 
    }
    return results;
  }

  private search() {
    var changedValues = true;
    this.dialogRef.close({data : changedValues});
  }

  private close() {
    this.dialogRef.close();
  }
}
