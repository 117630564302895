import { Component, OnInit, Inject, AfterViewInit, AfterViewChecked, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { MatCarouselSlide, MatCarouselSlideComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.css']
})
export class ModalGalleryComponent implements OnInit, AfterViewInit, AfterViewChecked {

	gallery: any;
  // mySwiper: any;
  scrollPosition: number;
  currentCategory: string;

  index = 0;

  constructor(public dialogRef: MatDialogRef<ModalGalleryComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit() {
    this.gallery = this.data.photos;
    // this.router.fragment.subscribe(fragment => { this.fragment = fragment; });

  	this.cdr.detectChanges();
  	// this.router.navigate(['gallery']);
    this.currentCategory = Object.keys(this.gallery)[0];
  	// this.router.navigate(['gallery/' + this.currentCategory]);
  }

	ngAfterViewChecked(): void {
	  // try {
   //    if(this.fragment) {
   //        document.querySelector('#' + this.fragment).scrollIntoView();
   //    }
	  // } catch (e) { }
	}

  ngAfterViewInit() {
  	// if (Swiper) {
	  //   this.mySwiper = new Swiper('.swiper-container', {
	  //       direction: 'vertical',
	  //       slidesPerView: 1,
	  //       spaceBetween: 30,
	  //       mousewheel: true,
	  //       pagination: {
	  //         el: '.swiper-pagination',
	  //         clickable: true,
	  //       },
	  //   });
	  // }
  }

  @HostListener('scroll', ['$event'])
    // checkScroll() {
    //   const scrollTop = event.srcElement.scrollTop;
    //   const componentPosition = this.el.nativeElement.offsetTop;
    //   const scrollPosition = window.pageYOffset;

    //   // if (scrollPosition >= componentPosition) {
    //   //   this.state = 'show';
    //   // } else {
    //   //   this.state = 'hide';
    //   // }

    // }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    }
  }

  selectCategory(category) {
    // if (category && category != this.currentCategory) {
    //   this.currentCategory = category;
    //   // this.router.navigate(['gallery/' + category]);
    //   var item = document.getElementById(category);
    //   item.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    // }
  }

  close() {
    this.dialogRef.close();
  }

}
