import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable} from 'rxjs';
import { map, startWith, distinctUntilChanged} from 'rxjs/operators';

import { HttpClient, HttpParams, HttpHeaders, HttpClientModule, HttpParameterCodec } from '@angular/common/http';

import { DatePipe } from '@angular/common'

import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';

import { SearchService } from '../search.service';
import { ResortsService } from '../resorts.service';

import { ModalGalleryComponent } from './modal-gallery/modal-gallery.component';

@Component({
  selector: 'app-resort-details',
  templateUrl: './resort-details.component.html',
  styleUrls: ['./resort-details.component.css']
})

export class ResortDetailsComponent implements OnInit {

  public detailsSearchForm: FormGroup;

  location = {
    lat:0,
    lng:0,
    zoom:0
  };

  searchStrings:any = [];
  searchMap:any = {};

  searchPlaces: Observable<string[]>;
  minCheckInDate = new Date();
  minCheckOutDate = new Date();
  maxCheckInDate: any;
  guestOptions: any;

  scrollPosition: number;

  resortMap: any = {};
  geocoder: any;
  map: any;
  marker: any;

  checkInDate: any;
  checkOutDate: any;
  guestCount: any;

  dialogRef: any;
	resort: any;
  country = '';
  state_code = '';
  city = '';
  name = '';

  listingUrl = '';

  datePipe = new DatePipe('en-US'); 

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private httpClient: HttpClient, private searchService: SearchService, private resortsService: ResortsService, public dialog: MatDialog) { }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    let button = document.getElementById('button-book-bar');
    if (window.pageYOffset >= 400) {
      button.classList.add('footer-sticky');
    } else {
      button.classList.remove('footer-sticky');
    }
  }

  ngOnInit() {
    let header = document.getElementById('header');
    header.classList.remove('header-sticky');

    this.guestOptions = this.searchService.getGuestOptions();

    this.listingUrl = this.router.url;
    window.scrollTo(0, 0);
  	// let header = document.getElementById('header');
		// header.classList.add('header-sticky');

    this.activatedRoute.queryParams.subscribe(params => {
      this.country = params["country"];
      this.state_code = params["state_code"];
      this.city = params["city"];
      this.name = params["name"];
    });

    // this.getDetails();

    this.getListing().subscribe((data)=>{
      if (data) {
        this.resort = data;

        if (this.resort.address) {
          // this.findLocationByAddress(this.resort.address);
        }

        if (this.resort.coordinates && this.resort.coordinates.lat && this.resort.coordinates.lng) {
          this.location.lat = this.resort.coordinates.lat;
          this.location.lng = this.resort.coordinates.lng;
          this.location.zoom = 12;
          this.marker = this.location;

          if (this.map) {
            this.map.triggerResize();
          }
        }

        if (this.resort.facilities && this.resort.facilities.length > 0) {
          for (var i = 0; i < this.resort.facilities.length; i++) {
            if (this.resort.facilities[i] === 'Outdoor pool') {
              this.resort.facilities.splice(i, 1);
            }
          }
        }

      } else {

      }
    });

    this.detailsSearchForm = new FormGroup({
      placeName: new FormControl(),
      checkInDate: new FormControl(),
      checkOutDate: new FormControl(),
      guestCount: new FormControl(),
      childrenCount: new FormControl()
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.country = params["country"];
      this.state_code = params["state_code"];
      this.city = params["city"];

      if (!this.searchService.getCountry() && params["country"]) {
        this.searchService.setCountry(params["country"]);
      }

      if (!this.searchService.getStateCode() && params["state_code"]) {
        this.searchService.setStateCode(params["state_code"]);
      }

      if (!this.searchService.getCity() && params["city"]) {
        this.searchService.setCity(params["city"]);
      }
      
      if (!this.searchService.getCheckIn() && params["checkIn"]) {
        this.checkInDate = params["checkIn"];
        this.searchService.setCheckIn(this.checkInDate);
      }

      if (!this.searchService.getCheckOut() && params["checkOut"]) {
        this.checkOutDate = params["checkOut"];
        this.detailsSearchForm.get("checkOutDate").setValue(this.checkOutDate);
      }

      if (!this.searchService.getGuests() && params["guests"]) {
        this.guestCount = params["guests"];
        this.searchService.setGuests(params["guests"]);
      }

      if (!this.searchService.getChildren() && params["children"]) {
        this.searchService.setChildren(params["children"]);
      }

      if (!this.searchService.getPlace() && params["place"]) {
        this.searchService.setPlace(params["place"]);
      }
    });

    this.detailsSearchForm.get("placeName").setValue(this.searchService.getPlace());

    if (this.searchService.getPlace()) {
      this.detailsSearchForm.get("placeName").setValue(this.searchService.getPlace());
    }

    if (this.searchService.getCheckIn()) {
      this.detailsSearchForm.get("checkInDate").setValue(this.searchService.getCheckIn());
    }

    if (this.searchService.getCheckOut()) {
      this.detailsSearchForm.get("checkOutDate").setValue(this.searchService.getCheckOut());
    }

    if (this.searchService.getGuests()) {
      this.detailsSearchForm.get("guestCount").setValue(this.searchService.getGuests());
    }

    if (this.searchService.getChildren()) {
      this.detailsSearchForm.get("childrenCount").setValue(this.searchService.getChildren());
    }

    this.detailsSearchForm.get('placeName').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      var region;

      for (var i in this.searchStrings) {
        region = this.searchStrings[i];
        if (this.detailsSearchForm.get('placeName').value && this.detailsSearchForm.get('placeName').value == region) {
          this.searchService.setStateCode(null);
          this.searchService.setCity(null);
          this.searchService.setName(null);
          this.searchService.setPlace(region);

          if (this.searchMap[region].type == 'STATE') {
            this.searchService.setStateCode(region);
          } else if (this.searchMap[region].type == 'CITY') {
            this.searchService.setCity(region);
          } else if (this.searchMap[region].type == 'NAME') {
            this.searchService.setName(region);
          }

          this.searchService.setCountry(this.searchMap[region].country);
          this.searchService.setStateCode(this.searchMap[region].state_code);

          if (this.searchMap[region].city) {
            this.searchService.setCity(this.searchMap[region].city);
          }

          if (this.searchMap[region].name) {
            this.searchService.setName(this.searchMap[region].name);
          }

          break;
        }
      }
    })

    this.detailsSearchForm.get('checkInDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.detailsSearchForm.get('checkInDate').value) {
        // this.searchService.setCheckIn(this.detailsSearchForm.get('checkInDate').value);
        this.searchService.setCheckIn(this.datePipe.transform(this.detailsSearchForm.get('checkInDate').value, 'yyyy-MM-dd'))
      }
      if (this.detailsSearchForm.get('checkInDate').value) {
        this.minCheckOutDate.setDate(this.detailsSearchForm.get('checkInDate').value.getDate() + 1);
      }
    })

    this.detailsSearchForm.get('checkOutDate').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.detailsSearchForm.get('checkOutDate').value) {
        // this.searchService.setCheckOut(this.detailsSearchForm.get('checkOutDate').value);
        this.searchService.setCheckOut(this.datePipe.transform(this.detailsSearchForm.get('checkOutDate').value, 'yyyy-MM-dd'))

        if (!this.detailsSearchForm.get('checkInDate').value) {
          this.maxCheckInDate = new Date();
          this.maxCheckInDate.setDate(this.detailsSearchForm.get('checkOutDate').value.getDate() - 1);
        }
      }
    })

    this.detailsSearchForm.get('guestCount').valueChanges.pipe(distinctUntilChanged()).subscribe(newValue => {
      if (this.detailsSearchForm.get('guestCount').value) {
        this.searchService.setGuests(this.detailsSearchForm.get('guestCount').value);
      }
    })

    this.minCheckOutDate.setDate(this.minCheckInDate.getDate() + 1);

    this.searchPlaces = this.detailsSearchForm.get('placeName').valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value))
    );
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();

    if (this.searchStrings && this.searchStrings.length > 0) {

    } else {
      this.searchStrings = this.resortsService.getSearchStrings();
      this.searchMap = this.resortsService.getSearchMap();
    }
    var results = [];
    if (this.searchStrings) {
      results = this.searchStrings.filter(option => option.toLowerCase().includes(filterValue)); 
    }
    return results;
  }

  private hasWhiteSpace(str) {
    return str.indexOf(' ') >= 0;
  }

  public openFilters() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { 
      "country": this.country,
      "state_code": this.state_code,
      "city":this.city,
      "checkInDate": this.checkInDate,
      "checkOutDate": this.checkOutDate,
      "guests":this.guestCount,
    };
    dialogConfig.height = '350px';
    dialogConfig.width = '300px';

    let navigationExtras: NavigationExtras = {
      queryParams: {
          "country": this.country,
          "state_code": this.state_code,
          "city":this.city,
          "name":this.name
      }
    };
  }

  public getListing() {
    var city;
    var country;
    var name;

    if (this.hasWhiteSpace(this.city)) {
      city = encodeURIComponent(this.city);
    } else {
      city = this.city;
    }

    if (this.hasWhiteSpace(this.country)) {
      country = encodeURIComponent(this.country);
    } else {
      country = this.country;
    }

    if (this.hasWhiteSpace(this.name)) {
      name = encodeURIComponent(this.name);
    } else {
      name = this.name;
    }

    // var city = encodeURIComponent(this.city);
    // var country = encodeURIComponent(this.country);
    var state_code = this.state_code;
    // var name = encodeURIComponent(this.name);
    return this.httpClient.get(`https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/resort?country=${ country }&state_code=${ state_code }&city=${ city }&name=${ name }`)
  }

  public getDetails() {
    const data = {
      "trip_url": "https://www.trip.com/hotels/los-angeles-hotel-detail-2002366/four-seasons-hotel-los-angeles-at-beverly-hills",
      "booking_url": "https://www.booking.com/hotel/us/the-beverly-hills.en-gb.html"
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const options = {
      headers
    };

    this.httpClient.post('https://41q7zy0h9g.execute-api.us-east-2.amazonaws.com/Prod/resort', data, options)
      .subscribe(response => {console.log(response)});
  }

  public book() {
    if (this.resort.booking_url) {
      var link = this.resort.booking_url + '?';

      if (this.searchService.getCheckIn() && this.searchService.getCheckOut()) {
        var checkIn = this.searchService.getCheckIn();
        var checkOut = this.searchService.getCheckOut();

        if (checkIn) {
          link = link + '&ci=' + checkIn;
        }

        if (checkOut) {
          link = link + '&co=' + checkOut;
        }
      }

      if (this.searchService.getGuests()) {
        link = link + '&adult=' + this.searchService.getGuests();
      }

      if (this.searchService.getChildren()) {
        link = link + '&children=' + this.searchService.getChildren();
      }

      window.open(link, "_blank");
    }
  }

  public openGallery() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { photos : this.resort.photos};
    dialogConfig.height = '100%';
    dialogConfig.width = '100%';

    let navigationExtras: NavigationExtras = {
      queryParams: {
          "country": this.country,
          "state_code": this.state_code,
          "city":this.city,
          "name":this.name
      }
    };

    this.dialog.open(ModalGalleryComponent, dialogConfig)
    .afterClosed().subscribe(value => {
      this.router.navigate(['listing'], navigationExtras);
    });
  }

}